
import { offset } from './scroller-utils'

function resize() {
  let preScroll = document.getElementsByClassName('pre-scrolly-container')[0]
  let divOffset = offset(preScroll)

  document.body.setAttribute('style', 
  `background: linear-gradient(to bottom, #222 0%, #222 ${divOffset.top + divOffset.height + 30}px, #f3f3f3 ${divOffset.top + divOffset.height + 30}px, #f3f3f3 100%);`)
}

resize()
window.addEventListener('resize', resize)

// import variables from '../../../data/variables'
// let breakPoint = variables.breakPoint
// let adHeight
// let windowWidth = document.body.clientWidth || document.documentElement.clientWidth || window.innerWidth 

// if (windowWidth > breakPoint) {
//   adHeight = 90
// } else {
//   adHeight = 250
// }
