import Scroller from '@newswire/scroller'
import { json, select } from 'd3'
import { intcomma, apnumber } from 'journalize'
import storyStats from '../../../data/story-stats.json'
import { removeEverything, createWorker, resizeSetupGroup, setUpAxes,
  resizeAxes, setUpAxesAndPayMarker, resizeAxesAndPayMarker, setupClusterBubbles, resizeClusterBubbles, 
  setupScaleBubbles, resizeScaleBubbles, setupAllAgenciesChart, resizeAllAgenciesChart, setupAllAgenciesTwoChart, 
  resizeAllAgenciesTwoChart, setMargins, appendAllGroupsContainer } from './scroller-helpers'
import { offset, hideTooltip, displayFinaleText, setZIndex } from './scroller-utils'

// in the future, we can pick a random story each time!
// make sure to change the data index in the IndexView in salaries/views.py as well
let storyData = storyStats[0]

Promise.all([
  json(`/api/departments/${storyData.slug}/positions/`),
  json('/api/departments')
]).then(([agencyData, allAgenciesData]) => {
  let positionData = agencyData.position_stats.find(d => d.label == storyData.position),
      bossData = agencyData.position_stats.find(d => d.label == storyData.boss)
  
  let agencySummary = agencyData.rollup

  select('#homepage-position-salary').html(`${intcomma(Math.round(positionData.median))}`)
  select('#homepage-position-total').html(`${intcomma(positionData.count)}`)
  select('#homepage-boss-salary').html(`${intcomma(Math.round(bossData.median))}`)
  select('#homepage-comptroller-median-salary').html(`${intcomma(Math.round(agencySummary.median))}`)

  let positionBossComparison = bossData.median / positionData.median

  if (positionBossComparison > 2 && positionBossComparison < 3) {
    select('#homepage-position-boss-comparison').html('more than double')
  }
  if (positionBossComparison > 3 && positionBossComparison < 4) {
    select('#homepage-position-boss-comparison').html('more than triple')
  }
  if (positionBossComparison > 4) {
    select('#homepage-position-boss-comparison').html(`more than ${apnumber(parseInt(positionBossComparison))} times`)
  }

  //////// INITIAL INTRO SLIDE ////////
  removeEverything()
  setMargins()
  appendAllGroupsContainer()
  setUpAxesAndPayMarker()
  createWorker(agencyData, allAgenciesData, storyData)
  resizeAxesAndPayMarker(agencyData, allAgenciesData, storyData)
  //////// INITIAL INTRO SLIDE ////////

  // SCROLLY MAGIC
  // Scroller has a tiny event emitter embedded in it!
  // sets up the scroller instance, pass in an array of all the scenes
  const scroller = new Scroller({
    scenes: document.querySelectorAll('.scene'),
    offset: 0.25,
  })

  scroller.on('init', () => {
    // console.log('Everything is ready to go!')
  })

  // starts up the IntersectionObserver
  scroller.init()

  // the `enter` event is triggered every time a scene crosses the threshold
  // each scene has its own window.resize events, we overwrite the last scene's resize events when we move to a new scene
  scroller.on('scene:enter', d => {
    if (d.index === 0) {
      removeEverything()
      setMargins()
      appendAllGroupsContainer()
      setUpAxesAndPayMarker()
      createWorker(agencyData, allAgenciesData, storyData)
      resizeAxesAndPayMarker(agencyData, allAgenciesData, storyData)

      window.onresize = () => { 
        removeEverything()
        setMargins()
        appendAllGroupsContainer()
        setUpAxesAndPayMarker()
        createWorker(agencyData, allAgenciesData, storyData)
        resizeAxesAndPayMarker(agencyData, allAgenciesData, storyData)
      }

      // hide other part of the last slide
      displayFinaleText('hide')

      // z-index 
      setZIndex('default')

      // tooltip
      hideTooltip()
    }
    if (d.index === 1) {
      removeEverything()
      setMargins()
      appendAllGroupsContainer()
      setupClusterBubbles()
      createWorker(agencyData, allAgenciesData, storyData)
      resizeClusterBubbles(agencyData, allAgenciesData, storyData)

      window.onresize = () => { 
        removeEverything()
        setMargins()
        appendAllGroupsContainer()
        setupClusterBubbles()
        createWorker(agencyData, allAgenciesData, storyData)
        resizeClusterBubbles(agencyData, allAgenciesData, storyData)
      }

      // hide other part of the last slide
      displayFinaleText('hide')

      // z-index 
      setZIndex('default')

      // tooltip
      hideTooltip()
    }
    if (d.index === 2) {
      removeEverything()
      setMargins()
      appendAllGroupsContainer()
      setupScaleBubbles()
      createWorker(agencyData, allAgenciesData, storyData)
      resizeScaleBubbles(agencyData, allAgenciesData, storyData)
      
      window.onresize = () => { 
        removeEverything()
        setMargins()
        appendAllGroupsContainer()
        setupScaleBubbles()
        createWorker(agencyData, allAgenciesData, storyData)
        resizeScaleBubbles(agencyData, allAgenciesData, storyData)
      }

      // hide other part of the last slide
      displayFinaleText('hide')

      // z-index 
      setZIndex('default')

      // tooltip
      hideTooltip()
    }
    if (d.index === 3) {
      removeEverything()
      setMargins()
      appendAllGroupsContainer()
      setupAllAgenciesChart()
      resizeAllAgenciesChart(agencyData, allAgenciesData, storyData)

      window.onresize = () => { 
        removeEverything()
        setMargins()
        appendAllGroupsContainer()
        setupAllAgenciesChart()
        resizeAllAgenciesChart(agencyData, allAgenciesData, storyData)
      }

      // hide other part of the last slide
      displayFinaleText('hide')

      // z-index 
      setZIndex('default')

      // tooltip
      hideTooltip()
    }
    if (d.index === 4) {
      removeEverything() 
      setMargins()
      appendAllGroupsContainer()
      setupAllAgenciesTwoChart()
      resizeAllAgenciesTwoChart(agencyData, allAgenciesData)

      window.onresize = () => { 
        removeEverything()
        setMargins()
        appendAllGroupsContainer()
        setupAllAgenciesTwoChart()
        resizeAllAgenciesTwoChart(agencyData, allAgenciesData)
      }

      // unhide other parts of the last slide
      displayFinaleText('show')

      // z-index 
      setZIndex('finale')

      // tooltip
      hideTooltip()
    }

    d.element.classList.add('active')
  })

  // the `exit` event is triggered every time a scene exits the threshold
  scroller.on('scene:exit', d => {
    d.element.classList.remove('active')
  })
})

let preScrollyContainer = document.querySelector('.pre-scrolly-container'),
  scrollyContainer = document.querySelector('.scrolly-container')

// load in the pre scrolly section automatically for large screens
if (window.innerHeight > offset(preScrollyContainer).top) {
  preScrollyContainer.classList.add('opacity-full')
}

// handle for resizes
window.addEventListener('resize', function() {
  if (window.innerHeight > offset(preScrollyContainer).top) {
    preScrollyContainer.classList.add('opacity-full')
  }
})

// fade in the pre scrolly text when you scroll to it on smaller screens
window.addEventListener('scroll', function() {
  if (window.scrollY > preScrollyContainer.offsetHeight) {
    preScrollyContainer.classList.add('opacity-full')
  }

  // fade out the scroll down arrow
  if (window.scrollY > offset(scrollyContainer).top) {
    document.querySelector('.scroll-arrow-container').classList.add('opacity-none')
  } else {
    document.querySelector('.scroll-arrow-container').classList.remove('opacity-none')
  }

  // skip button
  // show only when we're in the scrolly container
  if (window.scrollY > offset(scrollyContainer).top && window.scrollY < scrollyContainer.offsetHeight) {
    document.querySelector('.skip-button').classList.add('opacity-full')
  } else {
    document.querySelector('.skip-button').classList.remove('opacity-full')
  }
})
