import * as d3 from 'd3'
import * as journalize from 'journalize'
import getNodePos from './get-node-pos'

// this code needs to be called on the mousemove because the variables are recalculated each time
// to account for changes in window size
function positionTooltip(props) {
  // calculate most of the coordinates for tooltipping just once:
  let scr = { x: window.scrollX, y: window.scrollY, w: window.innerWidth, h: window.innerHeight }
  
  // get the position of the svg element that we're tooltipping over
  let svgpos = getNodePos(props.svgProp.node())
  
  // offset of the tooltip
  let dist = { x: 0, y: 35 }

  props.tooltipProp
    .classed('invisible', false)
    .html(() => {
        return `<div class="t-uppercase t-weight-bold has-text-gray-medium type">${props.label}</div>
        <div class="name t-weight-bold">${props.dataProp.label}</div>
        <table>
        <tbody>
        <tr><td class="key">Number of employees</td><td class="number">${journalize.intcomma(props.dataProp.count)}</td></tr>
        <tr><td class="key">${props.dataProp.count == 1 ? 'Salary' : 'Median salary'}</td><td class="number">$${journalize.intcomma(Math.round(props.dataProp.median))}</td></tr>
        </tbody>
        </table>`
      }
    )

  let m
  if (props.hoverText) {
    // we do this here because the mouse position and tooltip show up in separate places
    m = [props.dataProp.x, props.dataProp.y]
  } else {
    // gets the position of the mouse as [x, y] relative to the svg
    m = d3.mouse(props.svgProp.node())
  }

  m[0] += svgpos.x // takes into account the position of the svg relative to the body
  
  if (props.hoverText) {
    m[1] += svgpos.y // we do this for the hover text
  } else {
    m[1] = d3.event.pageY // https://bl.ocks.org/mbostock/1087001 // we did this because adding the svgpos.y for the homepage sticky beeswarm didn't work
  }

  // this needs to be here, or the tooltip size gets wonky
  props.tooltipProp.style('right', '')
  props.tooltipProp.style('left', '')
  props.tooltipProp.style('bottom', '')
  props.tooltipProp.style('top', '')

  // the right side of the chart
  if (m[0] > scr.x + scr.w / 2) {
    props.tooltipProp.style('right', `${scr.w - m[0]}px`)
  }
  else { // the left side of the chart
    props.tooltipProp.style('left', `${m[0] - dist.x}px`)
  }
  // the top side of the chart
  if (m[1] > scr.y + scr.h / 2) {
    props.tooltipProp.style('bottom', `${scr.h - m[1] + dist.y}px`)
  }
  else { // the bottom side of the chart
    props.tooltipProp.style('top', `${m[1] + dist.y}px`)
  }
}

export default positionTooltip

// // it's jolly rotten but <body> width/height can be smaller than the SVG it's carrying inside! :-((
// let body_sel = d3.select('body')
// // this is browser-dependent, but screw that for now!
// let body = { w: body_sel.node().offsetWidth, h: body_sel.node().offsetHeight }
// let doc = { w: document.width, h: document.height }

// if (props.homePage) {
  // on the homepage, the tooltipped chart is sticky
  // as a result, more scroll is taken into account because the page is scrolled
  // while the chart is still stuck to the page in the same page
  // m[1] = d3.event.pageY
// } else {
//   m[1] += svgpos.y
// }

// svgpos.y is the center of the svg
// console.log(d3.event.pageY)
// console.log('mouse position relative to svg', d3.mouse(props.svgProp.node())[1], 'position of svg relative to body', svgpos.y, 'how much has been scrolled', scr.y)
