import * as d3 from 'd3'

// http://stackoverflow.com/questions/288699/get-the-position-of-a-div-span-tag
// tells you the x,y position of the element relative to the page
// dirty hack/fixes for FireFox (code barfed on FF with NaN/NaN)
function getNodePos(el) {
  let body = d3.select('body').node()

  // this get the position of the node relative to the body EVEN if the node is sticky and has stayed in the same place
  for (var lx = 0, ly = 0; el != null && el != body; lx += (el.offsetLeft || el.clientLeft), ly += (el.offsetTop || el.clientTop), el = (el.offsetParent || el.parentNode));
  return {x: lx, y: ly}
}

export default getNodePos
