import * as d3 from 'd3'
import _ from 'lodash'
import variables from '../../../data/variables.json'
import positionTooltip from '../../utils/position-tooltip'
import { wordwrap } from 'd3-jetpack'
import { calculateSqrtScaleRange, getMaxMinPositionMedian, getMaxMinAgenciesMedian, createColorScale, boxForce } from '../charts/chart-utils'

// a reference to the default graphic container, change if needed
const container = d3.select('#scroll-graphic')
const getFrameWidth = () => container.node().offsetWidth

//////// VARIABLES ////////
let margin = {
  left: 25,
}

let breakPoint = variables.breakPoint

// time for d3 transitions
let transitionDuration = 500

// radii of the circles representing individual workers
let workerCircleRadius = 5,
  workerCircleCharge = 0.75

// this variable stops the simulation in resizeAllPositionsChart()
// otherwise the simulation keeps ticking and affects other bubbles
let scrolledAway
//////// VARIABLES ////////

//////// SETUP ////////
let svg = container.append('svg')
  .attr('class', 'scroll-graphic')

// tooltip
d3.select('main')
  .append('div')
  .attr('class', 'tooltip invisible t-size-xxs')
//////// SETUP ////////

//////// FUNCTIONS ////////
// create the individual worker
function createWorker(agencyData, allAgenciesData, storyData) {
  let positionData = agencyData.position_stats.find(d => d.label == storyData.position)

  let workerGroup = d3.select('.all-groups').append('g')
    .attr('class', 'worker-group')

  let colorScale = createColorScale(allAgenciesData.agency_stats)

  workerGroup
    .append('circle')
    .attr('r', workerCircleRadius)
    .attr('fill', colorScale(positionData.median))
    .attr('stroke', variables.beeswarmStrokeHover)
    .attr('stroke-width', '2px')

  workerGroup
    .append('text')
    .text(storyData.person_name)
    .attr('dy', -20)
    .attr('text-anchor', 'middle')
    .attr('class', 't-sans t-size-xs t-weight-bold t-uppercase t-lsp-m white-stroke')
}

// arrange individual worker group
function arrangeWorker(x, y, positionData) {
  let dimensions = resetFrameWidth()

  d3.select('.worker-group')
  .attr('transform', dimensions.svgWidth > breakPoint ? 
    `translate(${x(positionData.median)}, ${dimensions.chartHeight / 2})` :
    `translate(${dimensions.chartWidth / 2}, ${y(positionData.median)})`)
}

// set up the pay scale axis
function setUpAxes() {
  appendOtherGroup()

  appendAxisGroup()
}

// arrange the pay scale axis
function resizeAxes(agencyData, allAgenciesData, storyData) {
  let positionData = agencyData.position_stats.find(d => d.label == storyData.position)

  let { x } = createXScales(agencyData, allAgenciesData),
    { y } = createYScales(agencyData, allAgenciesData)

  let dimensions = resetFrameWidth()

  x.range([0, dimensions.chartWidth])
  y.range([0, dimensions.chartHeight])

  // translate the worker group to its median salary spot
  arrangeWorker(x, y, positionData)
  arrangeAxis()
  // generate the axis
  generateAxis(x, y)
}

// set up axes and pay marker
function setUpAxesAndPayMarker() {
  appendOtherGroup()

  appendAxisGroup()

  addPayMarker()
}

// arrange axes and pay marker
function resizeAxesAndPayMarker(agencyData, allAgenciesData, storyData) {
  let positionData = agencyData.position_stats.find(d => d.label == storyData.position)

  let { x } = createXScales(agencyData, allAgenciesData),
    { y } = createYScales(agencyData, allAgenciesData)

  let dimensions = resetFrameWidth()

  x.range([0, dimensions.chartWidth])
  y.range([0, dimensions.chartHeight])

  arrangeWorker(x, y, positionData)
  arrangeAxis()
  generateAxis(x, y)

  // position the pay marker line
  if (dimensions.svgWidth > breakPoint) {
    d3.select('.worker-pay-line')
      .attr('x1', x(positionData.median))
      .attr('x2', x(positionData.median))
      .attr('y1', dimensions.chartHeight)
      .attr('y2', dimensions.chartHeight)
      .transition()
      .duration(transitionDuration)
      .attr('y1', dimensions.chartHeight / 2)

    d3.select('.worker-pay-text')
      .text(d3.format('$.2s')(positionData.median))
      .attr('x', x(positionData.median))
      .attr('y', dimensions.chartHeight)
      .attr('dy', 15 + 3)
  } else {
    d3.select('.worker-pay-line')
      .attr('x1', margin.left)
      .attr('x2', margin.left)
      .attr('y1', y(positionData.median))
      .attr('y2', y(positionData.median))
      .transition()
      .duration(transitionDuration)
      .attr('x2', dimensions.chartWidth / 2)

    d3.select('.worker-pay-text')
      .text(d3.format('$.2s')(positionData.median))
      .attr('x', margin.left)
      .attr('y', y(positionData.median))
      .attr('dx', -15)
      .attr('dy', 3)
  }
}

// set up cluster of individuals within a positon at an agency
function setupClusterBubbles() {
  appendOtherGroup()

  // set up axes
  appendAxisGroup()

  // set up large surrounding circle annotation
  d3.select('.other-group')
    .append('circle')
    .attr('fill', 'none')
    .attr('stroke', '#797979')
    .attr('stroke-width', '0.25px')
    .attr('class', 'larger-bubble')
}

// arrange cluster of individuals within a position at an agency
function resizeClusterBubbles(agencyData, allAgenciesData, storyData) {
  let positionData = agencyData.position_stats.find(d => d.label == storyData.position)

  // radii for surrounding circles
  // based on the number of employees for the position
  let largeBubbleRadius = positionData.count,
    largerBubbleRadius = largeBubbleRadius + 10

  let { x } = createXScales(agencyData, allAgenciesData),
    { y } = createYScales(agencyData, allAgenciesData),
    colorScale = createColorScale(allAgenciesData.agency_stats)

  let dimensions = resetFrameWidth(),
    offGridX = -100,
    offGridY = -100

  x.range([0, dimensions.chartWidth])
  y.range([0, dimensions.chartHeight])

  arrangeWorker(x, y, positionData)
  arrangeAxis()
  generateAxis(x, y)

  // create the data
  let bubbleData = d3.range(1, positionData.count - 1).map(d => d = { value: d } )

  // remove things on resize
  d3.selectAll('.cluster-bubbles-group', '.cluster-bubble').remove()

  // create the force simulation
  let simulation = d3.forceSimulation(bubbleData)
    .force('charge', d3.forceManyBody().strength(workerCircleCharge))
    .force('x', d3.forceX(0).strength(0.2))
    .force('y', d3.forceY(0).strength(0.2))
    .force('collide', d3.forceCollide().radius(workerCircleRadius + 2))

  simulation
    .on('tick', ticked)
    .restart()

  function ticked() {
    bubbles
      .attr('cx', d => d.x)
      .attr('cy', d => d.y)
  }

  // append bubbles in the force simulation off to the side
  let bubblesGroup = d3.select('.other-group')
    .append('g')
    .attr('transform', dimensions.svgWidth > breakPoint ? 
      `translate(${offGridX},${dimensions.chartHeight / 2})` : 
      `translate(${dimensions.chartWidth / 2},${offGridY})`)
    .attr('class', 'cluster-bubbles-group')
  
  let bubbles = bubblesGroup.selectAll('circle')
    .data(bubbleData)
    .enter()
    .append('circle')
    .attr('r', workerCircleRadius)
    .attr('fill', colorScale(positionData.median))
    .attr('class', 'cluster-bubble')
  
  // bubble force transitions
  bubblesGroup
    .transition()
    .duration(1200)
    .attr('transform', dimensions.svgWidth > breakPoint ? 
      `translate(${x(positionData.median)},${dimensions.chartHeight / 2})` : 
      `translate(${dimensions.chartWidth / 2}, ${y(positionData.median)})`)
    .on('end', () => {
      simulation
        .force('radial', d3.forceRadial().radius(largeBubbleRadius).strength(0.5))

      d3.select('.position-label-group')
        .transition()
        .duration(transitionDuration)
        .attr('opacity', 1)
    })

  addPayMarker()
  addPositionLabel(positionData, storyData)

  // arrange surrounding circle
  d3.select('.larger-bubble')
    .attr('r', largerBubbleRadius)
    .call(transition)
    .attr('cx', dimensions.svgWidth > breakPoint ? x(positionData.median) : dimensions.chartWidth / 2)
    .attr('cy', dimensions.svgWidth > breakPoint ? dimensions.chartHeight / 2 : y(positionData.median))

  // arrange pay marker and position label
  if (dimensions.svgWidth > breakPoint) {
    d3.select('.position-label-text')
      .attr('x', x(positionData.median))
      .attr('y', dimensions.chartHeight / 2 + largerBubbleRadius + 25)

    d3.select('.worker-pay-line')
      .attr('x1', x(positionData.median))
      .attr('x2', x(positionData.median))
      .attr('y1', dimensions.chartHeight / 2)
      .attr('y2', dimensions.chartHeight)

    d3.select('.worker-pay-text')
      .text(d3.format('$.2s')(positionData.median))
      .attr('x', x(positionData.median))
      .attr('y', dimensions.chartHeight)
      .attr('dy', 15 + 3)
  } else {
    d3.select('.position-label-text')
      .attr('x', dimensions.chartWidth / 2)
      .attr('y', y(positionData.median) + largerBubbleRadius + 25)

    d3.select('.worker-pay-line')
      .attr('x1', margin.left)
      .attr('x2', dimensions.chartWidth / 2)
      .attr('y1', y(positionData.median))
      .attr('y2', y(positionData.median))

    d3.select('.worker-pay-text')
      .text(d3.format('$.2s')(positionData.median))
      .attr('x', margin.left)
      .attr('y', y(positionData.median))
      .attr('dx', -15)
      .attr('dy', 3)
  }
}

// set up all positions within an agency on a color scale
function setupScaleBubbles() {
  let dimensions = resetFrameWidth()

  appendOtherGroup()

  appendAxisGroup()

  if (dimensions.svgWidth > breakPoint) {
    d3.select('.other-group')
      .append('text')
      .text('How positions at the Parks and Wildlife Department are paid')
      .attr('y', -15)
      .style('opacity', 0)
      .transition()
      .duration(transitionDuration * 2)
      .style('opacity', 1)
      .attr('class', 't-sans t-size-s t-weight-bold')
  } else {
    d3.select('.other-group')
      .append('text')
      .text('How positions at the Parks and Wildlife')
      .attr('y', -50)
      .attr('class', 't-sans t-size-s t-weight-bold header-one')

    d3.select('.other-group')
      .append('text')
      .text('Department are paid')
      .attr('y', -35)
      .attr('class', 't-sans t-size-s t-weight-bold header-two')

    d3.selectAll('.header-one, .header-two')
      .style('opacity', 0)
      .transition()
      .duration(transitionDuration * 2)
      .style('opacity', 1)
      .attr('class', 't-sans t-size-s t-weight-bold')
  }
}

// set up all positions at an agency on a color scale
function resizeScaleBubbles(agencyData, allAgenciesData, storyData) {
  let positionData = agencyData.position_stats.find(d => d.label == storyData.position)

  // radii for surrounding circles
  // based on the number of employees for the position
  let largeBubbleRadius = positionData.count,
    largerBubbleRadius = largeBubbleRadius + 10

  let { x } = createXScales(agencyData, allAgenciesData),
    { y } = createYScales(agencyData, allAgenciesData),
    colorScale = createColorScale(allAgenciesData.agency_stats)
  
  scrolledAway = true

  let dimensions = resetFrameWidth(),
    offGridX = -300,
    offGridY = -300

  x.range([0, dimensions.chartWidth])
  y.range([0, dimensions.chartHeight])

  arrangeWorker(x, y, positionData)
  arrangeAxis()
  generateAxis(x, y)

  // radius scale
  let sqrtScaleRange = calculateSqrtScaleRange(storyData.agency, dimensions.svgWidth, dimensions.chartHeight, dimensions.chartWidth, breakPoint)

  let sqrtScale = d3.scaleSqrt().domain([0, d3.max(agencyData.position_stats, d => d.count)]).range(sqrtScaleRange)

  d3.selectAll('.scale-bubble').remove()
  
  // create force simulation
  let simulationScale = d3.forceSimulation(agencyData.position_stats)
    .force('x', dimensions.svgWidth > breakPoint ? d3.forceX(d => x(d.median)).strength(1) : d3.forceX(dimensions.chartWidth / 2))
    .force('y', dimensions.svgWidth > breakPoint ? d3.forceY(dimensions.chartHeight / 2) : d3.forceY(d => y(d.median)).strength(1))
    .force('collide', d3.forceCollide(d => sqrtScale(d.count) + 1))
    .force('boxForce',  () => { return boxForce(agencyData.position_stats, sqrtScale, dimensions.chartWidth, dimensions.chartHeight) })
    .stop()

  // manually make the simulation tick, so it's static
  // source: https://blockbuilder.org/mbostock/6526445e2b44303eebf21da3b6627320
  for (let i = 0 ; i < 300 ; ++i) simulationScale.tick()

  let positionX = agencyData.position_stats.find(d => d.label === storyData.position).x,
    positionY = agencyData.position_stats.find(d => d.label === storyData.position).y,
    positionBossX = agencyData.position_stats.find(d => d.label === storyData.boss).x,
    positionBossY = agencyData.position_stats.find(d => d.label === storyData.boss).y,
    positionYMax = Math.max(positionY, positionBossY) + 30,
    positionXMax = Math.max(positionX, positionBossX) + 50,
    positionBossCount = agencyData.position_stats.find(d => d.label === storyData.boss).count,
    positionBossMedian = agencyData.position_stats.find(d => d.label === storyData.boss).median

  // move the individual worker to its spot in the beeswarm
  d3.select('.worker-group')
    .transition()
    .delay(transitionDuration * 2) // we want the worker to move after the larger circle resizes
    .duration(transitionDuration)
    .attr('transform', () => {
      return `translate(
        ${positionX},
        ${positionY}
      )`
    })

  // append bubbles in the force simulation off to the side
  let scaleBubblesGroup = d3.select('.other-group')
    .append('g')
    .attr('class', 'scale-bubbles-group')

  scaleBubblesGroup.selectAll('.scale-bubble')
    .data(agencyData.position_stats)
    .enter()
    .append('circle')
    .attr('r', d => sqrtScale(d.count))
    .attr('cx', d => dimensions.svgWidth > breakPoint ? offGridX : d.x)
    .attr('cy', d => dimensions.svgWidth > breakPoint ? d.y : offGridY)
    .attr('fill', d => colorScale(d.median))
    .attr('opacity', 0.2)
    .attr('stroke', 'none')
    .attr('stroke-width', 0)
    .attr('class', 'scale-bubble')

  // append pay gap dotted lines
  let payGapDottedMarkers = d3.select('.other-group')
    .append('g')
    .attr('class', 'pay-gap-dotted-markers')

  payGapDottedMarkers.append('line').attr('class', 'pay-gap-dotted-line-one')
  payGapDottedMarkers.append('line').attr('class', 'pay-gap-dotted-line-two')

  // add the pay gap OUTSIDE of the on('end') event because it will get appended during another scene
  // if you end the transition early
  addPayGap()

  d3.select('.other-group')
    .append('circle')
    .attr('fill', 'none')
    .attr('class', 'larger-bubble')

  d3.select('.larger-bubble')
    .attr('fill', colorScale(positionData.median))
    .attr('r', largerBubbleRadius)
    .attr('cx', dimensions.svgWidth > breakPoint ? x(positionData.median) : dimensions.chartWidth / 2)
    .attr('cy', dimensions.svgWidth > breakPoint ? dimensions.chartHeight / 2 : y(positionData.median))
    .transition()
    .delay(transitionDuration)
    .duration(transitionDuration)
    .attr('r', sqrtScale(positionData.count))
    .transition()
    .duration(transitionDuration)
    .attr('cx', positionX)
    .attr('cy', positionY)

  // move the beeswarm into view
  if (dimensions.svgWidth > breakPoint) {
    scaleBubblesGroup.selectAll('.scale-bubble')
      .transition()
      .delay(transitionDuration) // delay this until after the larger circle finishes moving
      .duration(transitionDuration * 2)
      .attr('cx', d => d.x)
  } else {
    scaleBubblesGroup.selectAll('.scale-bubble')
      .transition()
      .delay(transitionDuration) // delay this until after the larger circle finishes moving
      .duration(transitionDuration * 2)
      .attr('cy', d => d.y)
  }

  // adding other position labels
  addOtherEntitiesLabel('position')

  // create labels for other positions
  if (dimensions.svgWidth > breakPoint) {
    let otherPositions = createOtherData(agencyData, 'position_stats', storyData.other_positions)
  
    positionOtherEntitiesLabels('position', otherPositions, sqrtScale)
  } 

  // boss group
  d3.select('.other-group')
    .append('circle')
    .attr('fill', colorScale(positionBossMedian))
    .attr('stroke', variables.beeswarmStrokeHover)
    .attr('stroke-width', '2px')
    .attr('r', sqrtScale(positionBossCount))
    .attr('class', 'boss-bubble')
    .attr('opacity', 0)

  d3.select('.other-group') 
    .append('text')
    .text(`${storyData.boss_label}`)
    .attr('text-anchor', 'middle')
    .attr('dy', -15)
    .attr('class', 'boss-text t-sans t-size-xs t-weight-bold t-uppercase white-stroke')
    .attr('opacity', 0)

  // arrange the pay gap annotation and boss text
  if (dimensions.svgWidth > breakPoint) {
    // pay gap transitions
    d3.select('.pay-gap-label-line-one')
      .attr('y1', positionYMax)
      .attr('y2', positionYMax)

    d3.select('.pay-gap-label-line-two')
      .attr('y1', positionYMax)
      .attr('y2', positionYMax)

    d3.select('.pay-gap-label-notch-one')
      .attr('x1', positionX)
      .attr('x2', positionX)
      .attr('y1', positionYMax + 5)
      .attr('y2', positionYMax - 5)

    d3.select('.pay-gap-label-notch-two')
      .attr('x1', positionBossX)
      .attr('x2', positionBossX)
      .attr('y1', positionYMax + 5)
      .attr('y2', positionYMax - 5)

    d3.select('.pay-gap-label-text')
      .attr('x', () => {
        return (positionBossX - positionX) / 2 + positionX
      })
      .attr('y', positionYMax + 5)

    d3.select('.pay-gap-label-line-one')
      .attr('x1', positionX)
      .attr('x2', positionX)
      .transition()
      .delay(transitionDuration * 3) // delay this until all of the other transitions are done
      .duration(transitionDuration)
      .attr('x2', (positionBossX - positionX) / 2 + positionX - d3.select('.pay-gap-label-text').node().getBBox().width / 2 - 10)

    // comes right after pay-gap-label-line-one transition
    d3.select('.pay-gap-label-line-two')
      .attr('x1', (positionBossX - positionX) / 2 + positionX + d3.select('.pay-gap-label-text').node().getBBox().width / 2 + 10)
      .attr('x2', (positionBossX - positionX) / 2 + positionX + d3.select('.pay-gap-label-text').node().getBBox().width / 2 + 10)
      .transition()
      .delay(transitionDuration * 3 + 300)
      .duration(transitionDuration)
      .attr('x2', positionBossX)

    d3.select('.boss-bubble')
      .attr('cx', positionBossX)
      .attr('cy', positionBossY)

    d3.select('.boss-text')
      .attr('x', positionBossX)
      .attr('y', positionBossY)

    d3.select('.pay-gap-dotted-line-one')
      .attr('x1', positionX)
      .attr('x2', positionX)
      .attr('y1', positionY)
      .attr('y2', positionY)
      .transition()
      .delay(transitionDuration * 3)
      .duration(transitionDuration)
      .attr('y2', positionYMax + 5)

     d3.select('.pay-gap-dotted-line-two')
      .attr('x1', positionBossX)
      .attr('x2', positionBossX)
      .attr('y1', positionBossY)
      .attr('y2', positionBossY)
      .transition()
      .delay(transitionDuration * 3)
      .duration(transitionDuration)
      .attr('y2', positionYMax + 5)
  } else {
    // pay gap transitions
    d3.select('.pay-gap-label-line-one')
      .attr('x1', positionXMax)
      .attr('x2', positionXMax)

    d3.select('.pay-gap-label-line-two')
      .attr('x1', positionXMax)
      .attr('x2', positionXMax)

    d3.select('.pay-gap-label-notch-one')
      .attr('x1', positionXMax - 5)
      .attr('x2', positionXMax + 5)
      .attr('y1', positionY)
      .attr('y2', positionY)

    d3.select('.pay-gap-label-notch-two')
      .attr('x1', positionXMax - 5)
      .attr('x2', positionXMax + 5)
      .attr('y1', positionBossY)
      .attr('y2', positionBossY)

    d3.select('.pay-gap-label-text')
      .attr('x', positionXMax)
      .attr('y', () => {
        return (positionBossY - positionY) / 2 + positionY
      })

    d3.select('.pay-gap-label-line-one')
      .attr('y1', positionY)
      .attr('y2', positionY)
      .transition()
      .delay(transitionDuration * 3) // delay this until all of the other transitions are done
      .duration(transitionDuration)
      .attr('y2', (positionBossY - positionY) / 2 + positionY - d3.select('.pay-gap-label-text').node().getBBox().height / 2 - 10)

    // comes right after pay-gap-label-line-one transition
    d3.select('.pay-gap-label-line-two')
      .attr('y1', (positionBossY - positionY) / 2 + positionY + 10)
      .attr('y2', (positionBossY - positionY) / 2 + positionY + 10)
      .transition()
      .delay(transitionDuration * 3 + 300)
      .duration(transitionDuration)
      .attr('y2', positionBossY)

    d3.select('.boss-bubble')
      .attr('cx', positionBossX)
      .attr('cy', positionBossY)

    d3.select('.boss-text')
      .attr('x', positionBossX)
      .attr('y', positionBossY)

    d3.select('.pay-gap-dotted-line-one')
      .attr('x1', positionXMax - 5)
      .attr('x2', positionXMax - 5)
      .attr('y1', positionY)
      .attr('y2', positionY)
      .transition()
      .delay(transitionDuration * 3)
      .duration(transitionDuration)
      .attr('x1', positionX)

     d3.select('.pay-gap-dotted-line-two')
      .attr('x1', positionXMax - 5)
      .attr('x2', positionXMax - 5)
      .attr('y1', positionBossY)
      .attr('y2', positionBossY)
      .transition()
      .delay(transitionDuration * 3)
      .duration(transitionDuration)
      .attr('x1', positionBossX)
  }

  // pay gap transitions
  // other positions labels transitions, same time as pay gap transitions
  d3.selectAll('.pay-gap-label-notch-one, .pay-gap-label-notch-two, .pay-gap-label-text, .boss-bubble, .boss-text, .other-position-group')
    .transition()
    .delay(transitionDuration * 3)
    .duration(transitionDuration)
    .attr('opacity', 1)
  // pay gap transitions
}

// set up cluster of all positions at an agency
function setupAllAgenciesChart() {
  let dimensions = resetFrameWidth()

  appendOtherGroup()

  appendAxisGroup()

  if (dimensions.svgWidth > breakPoint) {
    d3.select('.other-group')
      .append('text')
      .text(`Median salaries of all ${num_agencies} agencies`)
      .attr('y', -15)
      .style('opacity', 0)
      .transition()
      .duration(transitionDuration * 2)
      .style('opacity', 1)
      .attr('class', 't-sans t-size-s t-weight-bold')
  } else {
    d3.select('.other-group')
      .append('text')
      .text(`Median salaries of all ${num_agencies} agencies`)
      .attr('y', -35)
      .attr('class', 't-sans t-size-s t-weight-bold header-one')

    d3.selectAll('.header-one, .header-two')
      .style('opacity', 0)
      .transition()
      .duration(transitionDuration * 2)
      .style('opacity', 1)
      .attr('class', 't-sans t-size-s t-weight-bold')
  }
}

// arrange cluster of all positions at an agency
function resizeAllAgenciesChart(agencyData, allAgenciesData, storyData) {
  let { x, xAllAgencies } = createXScales(agencyData, allAgenciesData),
    { y, yAllAgencies } = createYScales(agencyData, allAgenciesData),
    colorScale = createColorScale(allAgenciesData.agency_stats)

  scrolledAway = false

  let dimensions = resetFrameWidth()

  x.range([0, dimensions.chartWidth])
  y.range([0, dimensions.chartHeight])
  xAllAgencies.range([0, dimensions.chartWidth])
  yAllAgencies.range([0, dimensions.chartHeight])

  arrangeAxis()

  if (dimensions.svgWidth > breakPoint) {
    d3.select('.mini-axis.axis--x').call(
      d3
        .axisBottom(x)
        .ticks(5)
        .tickFormat(d3.format('$~s'))
        .tickSize(-dimensions.chartHeight)
    )
    .transition()
    .duration(transitionDuration)
    .call(
      d3
        .axisBottom(xAllAgencies)
        .ticks(5)
        .tickFormat(d3.format('$~s'))
        .tickSize(-dimensions.chartHeight)
    )
  } else {
    d3.select('.mini-axis.axis--y').call(
      d3
        .axisLeft(y)
        .ticks(5)
        .tickFormat(d3.format('$~s'))
        .tickSize(-dimensions.chartWidth)
    )
    .transition()
    .duration(transitionDuration)
    .call(
      d3
        .axisLeft(yAllAgencies)
        .ticks(5)
        .tickFormat(d3.format('$~s'))
        .tickSize(-dimensions.chartWidth)
    )
  }

  d3.selectAll('.mini-axis .tick text').attr('dy', dimensions.svgWidth > breakPoint ? 15 : 2)

  // radius scale
  let sqrtScaleRange = calculateSqrtScaleRange(storyData.agency, dimensions.svgWidth, dimensions.chartHeight, dimensions.chartWidth, breakPoint)

  let sqrtScale = d3.scaleSqrt().domain([0, d3.max(agencyData.position_stats, d => d.count)]).range(sqrtScaleRange)

  // agency stats
  let agencyMedianX = xAllAgencies(allAgenciesData.agency_stats.find(d => d.label === storyData.agency).median),
    agencyMedianY = yAllAgencies(allAgenciesData.agency_stats.find(d => d.label === storyData.agency).median)

  let agencyCount = allAgenciesData.agency_stats.find(d => d.label === storyData.agency).count

  // radius scale
  let sqrtScaleRangeAllAgencies = calculateSqrtScaleRange('all agencies', dimensions.svgWidth, dimensions.chartHeight, dimensions.chartWidth, breakPoint)

  let sqrtScaleAllAgencies = d3.scaleSqrt().domain([0, d3.max(allAgenciesData.agency_stats, d => d.count)]).range(sqrtScaleRangeAllAgencies)

  let offGridX = -300,
    offGridY = -300

  d3.selectAll('.scale-bubble').remove()

  // create force simulation
  let simulationScaleAllPositions = d3.forceSimulation(agencyData.position_stats)
    .force('charge', d3.forceManyBody().strength(-3)) // negative makes them not jitter into eachother
    .force('x', dimensions.svgWidth > breakPoint ? d3.forceX(d => x(d.median)).strength(1) : d3.forceX(dimensions.chartWidth / 2))
    .force('y', dimensions.svgWidth > breakPoint ? d3.forceY(dimensions.chartHeight / 2) : d3.forceY(d => y(d.median)).strength(1))
    .force('collide', d3.forceCollide(d => sqrtScale(d.count) + 1).strength(1))
    .force('boxForce', () => { return boxForce(agencyData.position_stats, sqrtScaleAllAgencies, dimensions.chartWidth, dimensions.chartHeight) })

  simulationScaleAllPositions
    .on('tick', tickedAllPositions)
    .restart()

  function tickedAllPositions() {
    if (scrolledAway === true) {
      simulationScaleAllPositions.stop()
    } else {
      d3.selectAll('.scale-bubble')
        .attr('cx', d => d.x)
        .attr('cy', d => d.y)
    }
  }

  // append bubbles in the force simulation off to the side
  let scaleBubblesGroup = d3.select('.other-group')
    .append('g')
    .attr('class', 'scale-bubbles-group')

  scaleBubblesGroup.selectAll('.scale-bubble')
    .data(agencyData.position_stats)
    .enter()
    .append('circle')
    .attr('r', d => sqrtScale(d.count))
    .attr('cx', d => d.x)
    .attr('cy', d => d.y)
    .attr('opacity', 0.2)
    .attr('fill', d => colorScale(d.median))
    .attr('class', 'scale-bubble')
    .transition()
    .duration(100)
    .attr('r', d => sqrtScale(d.count) / 1.5)

  // create force simulation
  let simulationScale = d3.forceSimulation(allAgenciesData.agency_stats)
    .force('x', dimensions.svgWidth > breakPoint ? d3.forceX(d => xAllAgencies(d.median)).strength(1) : d3.forceX(dimensions.chartWidth / 2))
    .force('y', dimensions.svgWidth > breakPoint ? d3.forceY(dimensions.chartHeight / 2) : d3.forceY(d => yAllAgencies(d.median)).strength(1))
    .force('collide', d3.forceCollide(d => sqrtScaleAllAgencies(d.count) + 1))
    .stop()

  // manually make the simulation tick, so it's static
  // source: https://blockbuilder.org/mbostock/6526445e2b44303eebf21da3b6627320
  for (let i = 0 ; i < 300 ; ++i) simulationScale.tick()

  // these variables need to go under the simulation (it generates the x and y values)
  let agencyX = allAgenciesData.agency_stats.find(d => d.label === storyData.agency).x,
    agencyY = allAgenciesData.agency_stats.find(d => d.label === storyData.agency).y

  d3.selectAll('.scale-bubble')
    .transition()
    .duration(transitionDuration * 2)
    .attr('cx', agencyX)
    .attr('cy', agencyY)
    .on('end', function() {
      simulationScaleAllPositions.stop()

      d3.select(this)
        .transition()
        .delay(transitionDuration)
        .duration(transitionDuration)
        .attr('r', 0)
        .remove()
    })

  scaleBubblesGroup.selectAll('.scale-bubble-all-agencies')
    .data(allAgenciesData.agency_stats)
    .enter()
    .append('circle')
    .attr('r', d => sqrtScaleAllAgencies(d.count))
    .attr('cx', d => dimensions.svgWidth > breakPoint ? offGridX : d.x)
    .attr('cy', d => dimensions.svgWidth > breakPoint ? d.y : offGridY)
    .attr('fill', d => colorScale(d.median))
    .attr('opacity', 0.2)
    .attr('class', 'scale-bubble-all-agencies')

  if (dimensions.svgWidth > breakPoint) {
    scaleBubblesGroup.selectAll('.scale-bubble-all-agencies')
      .transition()
      .delay(transitionDuration)
      .duration(transitionDuration * 2)
      .attr('cx', d => d.x)
  } else {
    scaleBubblesGroup.selectAll('.scale-bubble-all-agencies')
      .transition()
      .delay(transitionDuration)
      .duration(transitionDuration * 2)
      .attr('cy', d => d.y)
  }

  addPayMarker()

  d3.select('.worker-pay-text')
    .attr('opacity', 0)

  d3.select('.other-group')
    .append('circle')
    .attr('stroke', variables.beeswarmStrokeHover)
    .attr('stroke-width', '2px')
    .attr('class', 'even-larger-bubble')
    .attr('opacity', 0)

  d3.select('.even-larger-bubble')
    .attr('fill', colorScale(agencyData.rollup.median))
    .attr('r', sqrtScaleAllAgencies(agencyCount))
    .attr('cx', agencyX)
    .attr('cy', agencyY)
    .transition()
    .delay(transitionDuration * 3)
    .duration(transitionDuration)
    .attr('opacity', 1)

  let otherAgencies
  if (dimensions.svgWidth > breakPoint) {
    // data for other agency labels
    otherAgencies = createOtherData(allAgenciesData, 'agency_stats', ['Texas Department of Criminal Justice', 'Comptroller of Public Accounts, Judiciary Section'])
  } else {
    otherAgencies = createOtherData(allAgenciesData, 'agency_stats', ['Comptroller of Public Accounts, Judiciary Section'])
  }

  addOtherEntitiesLabel('agency')
  
  // position other agencies labels
  positionOtherEntitiesLabels('agency', otherAgencies, sqrtScaleAllAgencies)

  addAgencyLabel(storyData)

  // arrange agency label and pay marker
  if (dimensions.svgWidth > breakPoint) {
    d3.select('.agency-label-text')
      .attr('x', agencyX)
      .attr('y', agencyY)

    d3.select('.worker-pay-line')
      .attr('x1', agencyX)
      .attr('x2', agencyX)
      .attr('y1', dimensions.chartHeight)
      .attr('y2', dimensions.chartHeight)
      .transition()
      .delay(transitionDuration * 3)
      .duration(transitionDuration)
      .attr('y1', agencyY)

    d3.select('.worker-pay-text')
      .text(d3.format('$.2s')(agencyData.rollup.median)) // change to 0.3 if you want one decimal place
      .attr('x', agencyX)
      .attr('y', dimensions.chartHeight)
      .attr('dy', 15 + 3)
      .transition()
      .delay(transitionDuration * 3)
      .duration(transitionDuration)
      .attr('opacity', 1)
  } else {
    d3.select('.agency-label-text')
      .attr('x', agencyX)
      .attr('y', agencyY)

    d3.select('.worker-pay-line')
      .attr('x1', margin.left)
      .attr('x2', margin.left)
      .attr('y1', agencyY)
      .attr('y2', agencyY)
      .transition()
      .delay(transitionDuration * 3)
      .duration(transitionDuration)
      .attr('x2', agencyX)

    d3.select('.worker-pay-text')
      .text(d3.format('$.2s')(agencyData.rollup.median)) // change to 0.3 if you want one decimal place
      .attr('x', margin.left)
      .attr('y', agencyY)
      .attr('dx', -15)
      .attr('dy', 3)
      .transition()
      .delay(transitionDuration * 3)
      .duration(transitionDuration)
      .attr('opacity', 1)
  }

  d3.select('.agency-label-group')
    .transition()
    .delay(transitionDuration * 3)
    .duration(transitionDuration)
    .attr('opacity', 1)

  // other positions labels transitions
  d3.select('.other-agency-group')
    .transition()
    .delay(transitionDuration * 3)
    .duration(transitionDuration)
    .attr('opacity', 1)
}

// set up all agencies in comptroller
function setupAllAgenciesTwoChart() {
  appendOtherGroup()

  appendAxisGroup()

  d3.select('.other-group')
    .append('g')
    .attr('class', 'voronoi-group')
    
  d3.select('#scroll-graphic-text')
    .style('opacity', 0)
}

// arrange all agencies in comptroller
function resizeAllAgenciesTwoChart(agencyData, allAgenciesData) {
  let { x, xAllAgencies } = createXScales(agencyData, allAgenciesData),
    { y, yAllAgencies } = createYScales(agencyData, allAgenciesData),
    colorScale = createColorScale(allAgenciesData.agency_stats)

  let dimensions = resetFrameWidth()

  x.range([0, dimensions.chartWidth])
  y.range([0, dimensions.chartHeight])
  xAllAgencies.range([0, dimensions.chartWidth])
  yAllAgencies.range([0, dimensions.chartHeight])

  arrangeAxis()
  generateAxis(xAllAgencies, yAllAgencies)
  
  // radius scale
  let sqrtScaleRangeAllAgencies = calculateSqrtScaleRange('all agencies', dimensions.svgWidth, dimensions.chartHeight, dimensions.chartWidth, breakPoint)
  
  let sqrtScaleAllAgencies = d3.scaleSqrt().domain([0, d3.max(allAgenciesData.agency_stats, d => d.count)]).range(sqrtScaleRangeAllAgencies)

  // create force simulation
  let simulationScale = d3.forceSimulation(allAgenciesData.agency_stats)
    .force('x', dimensions.svgWidth > breakPoint ? d3.forceX(d => xAllAgencies(d.median)).strength(1) : d3.forceX(dimensions.chartWidth / 2))
    .force('y', dimensions.svgWidth > breakPoint ? d3.forceY(dimensions.chartHeight / 2) : d3.forceY(d => yAllAgencies(d.median)).strength(1))
    .force('collide', d3.forceCollide(d => sqrtScaleAllAgencies(d.count) + 1))
    .stop()

  // manually make the simulation tick, so it's static
  // source: https://blockbuilder.org/mbostock/6526445e2b44303eebf21da3b6627320
  for (let i = 0 ; i < 300 ; ++i) simulationScale.tick()

  // create voronoi layout
  let voronoi = d3.voronoi()
    .size([dimensions.chartWidth, dimensions.chartHeight])
    .x(d => d.x)
    .y(d => d.y)
    .polygons(allAgenciesData.agency_stats)

  // remove and recalculate these elements in the resize
  d3.selectAll('.cell').remove()
  d3.selectAll('.scale-bubble-all-agencies').remove()
  d3.selectAll('.path-salary-voronoi').remove()

  // append voronoi layout
  let cells = d3.select('.voronoi-group').selectAll('.cell')
    .data(voronoi)
    .enter()
    .append('g')
    .attr('class', 'cell')

  cells.append('circle')
    .attr('r', d => {
      if (d) return sqrtScaleAllAgencies(d.data.count)
    })
    .attr('cx', d => {
      if (d) return d.data.x
    })
    .attr('cy', d => {
      if (d) return d.data.y
    })
    .attr('fill', d => {
      if (d) return colorScale(d.data.median)
    })
    .attr('opacity', 0.2)
    .transition()
    .duration(transitionDuration)
    .attr('opacity', 1)
    .attr('class', 'scale-bubble-all-agencies')

  cells.append('path')
    .attr('d', d => { 
      if (d) return 'M' + d.join('L') + 'Z' 
    })
    .attr('class', 'path-voronoi-salary')
    .style('cursor', 'pointer')
    .attr('opacity', 0)
    .on('mousemove', function(d) {
      d3.select(this.parentNode).select('circle')
        .attr('stroke', variables.beeswarmStrokeHover)
        .attr('stroke-width', '2px')
        
      positionTooltip({
        dataProp: d.data,
        svgProp: d3.select('.scroll-graphic'),
        tooltipProp: d3.select('.tooltip'),
        bubble: d3.select(this),
        type: 'all-agencies',
        label: 'agency',
        homePage: true,
      })
    })
    .on('mouseout', function(d) {
      d3.select(this.parentNode).select('circle')
        .attr('stroke', 'none')
        .attr('stroke-width', '0')

      d3.select('.tooltip').classed('invisible', true)
    })
    .on('click', function(d) {
      if (dimensions.svgWidth > breakPoint) {
        // get the current url from the API
        window.location.href = d.data.url

        window.dataLayer.push({ 
          event: 'customDataVisuals', 
          gaCategory: 'data visuals - salaries',
          gaAction: 'beeswarm', 
          gaLabel: 'homepage', 
        })
      }
    })

  // agency median salary line
  let agencyMedSalary = d3.select('.other-group').append('g')
    .attr('class', 'agency-median-salary')

  agencyMedSalary.append('line')
    .attr('class', 'line')
    .attr('stroke', '#222')
    .attr('stroke-width', '1px')

  agencyMedSalary.append('text')
    .text('Median salary')
    .attr('class', 'label t-size-xs t-weight-bold t-sans t-uppercase white-stroke')

  agencyMedSalary.append('text')
    .text(d3.format('$.2s')(allAgenciesData.rollup.median))
    .attr('class', 'amount-label t-size-xs t-weight-bold t-sans t-uppercase white-stroke')
    .attr('text-anchor', 'middle')

  if (dimensions.svgWidth > breakPoint) {
    d3.select('.agency-median-salary')
      .attr('transform', `translate(${xAllAgencies(allAgenciesData.rollup.median)}, 0)`)
  
    d3.select('.agency-median-salary line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 5)
      .attr('y2', dimensions.chartHeight)

     d3.select('.agency-median-salary .label')
      .attr('x', 0)
      .attr('y', 0)
      .attr('text-anchor', 'start')

    d3.select('.agency-median-salary .amount-label')
      .attr('x', 0)
      .attr('y', dimensions.chartHeight + 18)
  } else {
    d3.select('.agency-median-salary')
      .attr('transform', `translate(${margin.left}, ${yAllAgencies(allAgenciesData.rollup.median)})`)

    d3.select('.agency-median-salary line')
      .attr('x1', 0)
      .attr('x2', dimensions.chartWidth)
      .attr('y1', 0)
      .attr('y2', 0)

    d3.select('.agency-median-salary .label')
      .attr('x', dimensions.chartWidth)
      .attr('y', -10)
      .attr('text-anchor', 'end')

    d3.select('.agency-median-salary .amount-label')
      .attr('x', -20)
      .attr('y', 0)
  }
}
//////// FUNCTIONS ////////

//////// HELPER FUNCTIONS ////////
// resize svg based on current container width
function resizeSetupGroup() {
  let dimensions = resetFrameWidth()

  svg.attr('width', dimensions.svgWidth).attr('height', dimensions.svgHeight)
}

// get new dimensions everytime the window resizes
function resetFrameWidth() {
  svg.attr('width', 0)

  let frameWidth = getFrameWidth()

  let svgWidth = frameWidth,
    svgHeight

  // set height of the svg based on the breakpoint
  if (svgWidth > breakPoint) {
    svgHeight = 375
  } else {
    svgHeight = 400
  }

  svg.attr('width', svgWidth).attr('height', svgHeight)

  return {
    svgWidth: svgWidth,
    svgHeight: svgHeight,
    chartWidth: svgWidth - margin.right - margin.left,
    chartHeight: svgHeight - margin.top - margin.bottom
  }
}

// set margins based on the window width
function setMargins() {
  // set margins based on breakpoints
  // can't use resetFrameWidth() until after the svg has been created
  if (resetFrameWidth().svgWidth > breakPoint) {
    margin.right = 85 // to fit the other agencies labels
    margin.bottom = 35
    margin.top = 30
  } else {
    margin.right = 35
    margin.bottom = 30
    margin.top = 60
  }
}

// append all groups container
function appendAllGroupsContainer() {
  svg.append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)
    .attr('class', 'all-groups')
}

// append other group
function appendOtherGroup() {
  d3.select('.all-groups').append('g')
    .attr('class', 'other-group')
}

// append axis group
function appendAxisGroup() {
  let dimensions = resetFrameWidth()

  d3.select('.other-group')
    .append('g')
    .attr('class', dimensions.svgWidth > breakPoint ? 'mini-axis axis axis--x' : 'mini-axis axis axis--y')
    .style('font-size', '12px')
}

// create x scales
function createXScales(agencyData, allAgenciesData) {
  let { positionMin, positionMax } = getMaxMinPositionMedian(agencyData.position_stats),
    { agenciesMin, agenciesMax } = getMaxMinAgenciesMedian(allAgenciesData.agency_stats)

  let x = d3
    .scaleLinear()
    .domain([0, positionMax])
    .nice()

  let xAllAgencies = d3
    .scaleLinear()
    .domain([agenciesMin, agenciesMax])
    .nice()

  return { x, xAllAgencies }
}

// create y scales
function createYScales(agencyData, allAgenciesData) {
  let { positionMin, positionMax } = getMaxMinPositionMedian(agencyData.position_stats),
    { agenciesMin, agenciesMax } = getMaxMinAgenciesMedian(allAgenciesData.agency_stats)

  let y = d3
    .scaleLinear()
    .domain([positionMin, positionMax])
    .nice()

  let yAllAgencies = d3
    .scaleLinear()
    .domain([agenciesMin, agenciesMax])
    .nice()

  return { y, yAllAgencies }
}

// arrange the axis group
function arrangeAxis() {
  let dimensions = resetFrameWidth()
  
  d3.select(dimensions.svgWidth > breakPoint ? '.mini-axis.axis--x' : '.mini-axis.axis--y')
    .attr('transform', dimensions.svgWidth > breakPoint ?
      `translate(0, ${dimensions.chartHeight})` :
      `translate(${margin.left}, 0)`)
}

// call the axis generator
function generateAxis(x, y) {
  let dimensions = resetFrameWidth()

  if (dimensions.svgWidth > breakPoint) {
    d3.select('.mini-axis.axis--x').call(
      d3
        .axisBottom(x)
        .ticks(5)
        .tickFormat(d3.format('$~s'))
        .tickSize(-dimensions.chartHeight)
    )
  } else {
    d3.select('.mini-axis.axis--y').call(
      d3
        .axisLeft(y)
        .ticks(5)
        .tickFormat(d3.format('$~s'))
        .tickSize(-dimensions.chartWidth)
    )
  }

  d3.selectAll('.mini-axis .tick text').attr('dy', dimensions.svgWidth > breakPoint ? 15 : 2)
}

// generate data for other entity labels
function createOtherData(data, statsName, names) {
  // console.log(data);
  // console.log(statsName);
  // console.log(names);
  let otherEntitiesData = names.map(name => {
    return { 
      x: data[statsName].find(d => d.label === name).x, 
      y: data[statsName].find(d => d.label === name).y, 
      r: data[statsName].find(d => d.label === name).count,
      label: name
    }
  })

  return otherEntitiesData
}

// create labels for other positions at an agency or other agencies
function addOtherEntitiesLabel(type) {
  d3.select('.other-group').append('g')
    .attr('class', `other-${type}-group`)
    .attr('opacity', 0)
}

// arrange labels for other positions at an agency
function positionOtherEntitiesLabels(type, otherEntities, sqrtScale) {
  let otherEntitiesLabels = d3.select(`.other-${type}-group`).selectAll(`.other-${type}-label`)
    .data(otherEntities)
    .enter()
    .append('g')
    .attr('transform', d => {
      if (d.y - sqrtScale(d.r) > 50) {
        return `translate(${d.x}, ${d.y - sqrtScale(d.r) - 50})`
      } else {
        return `translate(${d.x}, ${d.y})`
      }
    })
    .attr('class', `other-${type}-label t-sans`)

  otherEntitiesLabels
    .append('text')
    .text(d => d.label)
    .attr('text-anchor', 'middle')
    .attr('font-size', '12px')
    .attr('fill', '#797979')
    .attr('class', 'white-stroke t-weight-bold')

  // wrap the text with d3-jetpack's wordwrap
  otherEntitiesLabels.selectAll('text').each(function(d) {
    d3.select(this)
      .text('')
      .tspans(wordwrap(d.label, 25))
  })

  otherEntitiesLabels
    .append('line')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', function() {
      return 5 + (15 * (d3.select(this.parentNode).selectAll('tspan').nodes().length - 1))
    })
    .attr('y2', function(d) {
      if (d.y - sqrtScale(d.r) > 50) {
        return sqrtScale(d.r) + 50
      } else {
        return 5 + (15 * (d3.select(this.parentNode).selectAll('tspan').nodes().length - 1))
      }
    })
    .attr('stroke', '#797979')
    .attr('stroke-width', '0.75px')
}

// create pay gap label (between x employee and their boss)
function addPayGap() {
  let payGapLabel = d3.selectAll('.other-group').append('g')
    .attr('class', 'pay-gap-label-group')

  payGapLabel.append('line')
    .attr('stroke', '#222')
    .attr('stroke-width', '1.5px')
    .attr('class', 'pay-gap-label-notch-one')
    .attr('opacity', 0)

  payGapLabel.append('line')
    .attr('stroke', '#222')
    .attr('stroke-width', '1.5px')
    .attr('class', 'pay-gap-label-line-one')

  payGapLabel.append('line')
    .attr('stroke', '#222')
    .attr('stroke-width', '1.5px')
    .attr('class', 'pay-gap-label-line-two')

  payGapLabel.append('line')
    .attr('stroke', '#222')
    .attr('stroke-width', '1.5px')
    .attr('class', 'pay-gap-label-notch-two')
    .attr('opacity', 0)

  payGapLabel.append('text')
    .text('Pay gap')
    .attr('text-anchor', 'middle')
    .attr('class', 't-sans t-uppercase t-weight-bold t-lsp-m t-size-xs pay-gap-label-text white-stroke')
    .attr('opacity', 0)
}

// create position label for one position at an agency
function addPositionLabel(positionData, storyData) {
  // set up position annotation
  let positionLabel = d3.select('.other-group').append('g')
    .attr('class', 'position-label-group')
    .attr('opacity', 0)

  positionLabel.append('text')
    .text(`${positionData.count} ${storyData.position_label}s`)
    .attr('text-anchor', 'middle')
    .attr('class', 't-sans t-uppercase t-weight-bold t-lsp-m t-size-xs position-label-text white-stroke')
}

// create pay marker for worker
function addPayMarker() {
  let workerPayMarker = d3.select('.other-group')
    .append('g')
    .attr('class', 'worker-pay-marker')

  workerPayMarker
    .append('line')
    .attr('class', 'worker-pay-line')

  workerPayMarker
    .append('text')
    .attr('class', 'worker-pay-text white-stroke')
    .style('font-size', '12px')
}

// create agency label
function addAgencyLabel(storyData) {
  let agencyLabel = d3.select('.other-group').append('g')
    .attr('class', 'agency-label-group')
    .attr('opacity', 0)

  agencyLabel.append('text')
    .text(storyData.agency)
    .attr('text-anchor', 'middle')
    .attr('dy', -20)
    .attr('class', 't-sans t-uppercase t-weight-bold t-lsp-m t-size-s agency-label-text white-stroke')
}

// TWEEN TRANSITION
function transition(path) {
  path.transition()
    .duration(2000)
    .attrTween('stroke-dasharray', tweenDash)
}

function tweenDash() {
  let l = this.getTotalLength()
  let i = d3.interpolateString('0,' + l, l + ',' + l)
  return function (t) {
    return i(t)
  }
}

// FUNCTIONS FOR REMOVING THINGS
function removeOtherGroup() {
  d3.select('.other-group').remove()
}

function removeEverything() {
  d3.selectAll('.scroll-graphic g').remove()
}

function removeEverythingExcept(selector) {
  d3.selectAll(`:not(${selector})`).remove()
}
//////// HELPER FUNCTIONS ////////

export {
  removeEverything, createWorker, resizeSetupGroup, setUpAxes, resizeAxes, setUpAxesAndPayMarker,
  resizeAxesAndPayMarker, setupClusterBubbles, resizeClusterBubbles, setupScaleBubbles, resizeScaleBubbles, 
  setupAllAgenciesChart,resizeAllAgenciesChart, setupAllAgenciesTwoChart, resizeAllAgenciesTwoChart, setMargins,
  appendAllGroupsContainer
}
