// scroll to the last scene when you press the skip button
document.querySelector('.skip-button button').onclick = skipToEnd
document.querySelector('.scroll-arrow-container').onclick = skipToEnd

function skipToEnd() {
  let element = document.querySelector('.scene.end'),
    y =
      element.getBoundingClientRect().top +
      window.pageYOffset

  window.scrollTo({ top: y, behavior: 'smooth' });

  window.dataLayer.push({ 
    event: 'customDataVisuals', 
    gaCategory: 'data visuals - salaries',
    gaAction: 'skip scrolly',
    gaLabel: 'homepage',
  })
}
