import * as d3 from 'd3'
import variables from '../../../data/variables.json'

// calculate range for radius scale
function calculateSqrtScaleRange(agencyName, svgWidth, chartHeight, chartWidth, breakPoint) {
  // for certain agencies, we need to make the circles smaller
  // this needs a better solution
  let denominator,
    largeAgencies = ['Health and Human Services Commission', 'Texas Workforce Commission', 'Department of State Health Services']

  if (largeAgencies.includes(agencyName)) denominator = 15
  else denominator = 10

  // 2 is lower bound because it's still visible/clickable
  // chartHeight / 10 is arbitrary, just want bubbles to fit on the page
  let sqrtScaleRange = svgWidth > breakPoint ? [2, chartHeight / denominator] : [2, chartWidth / denominator]

  return sqrtScaleRange
}

// get min/max median salaries of all positions in agency
function getMaxMinPositionMedian(positionStats) {
  // get the min/max median salaries of all positions
  let positionMin = d3.min(positionStats, d => d.median),
    positionMax = d3.max(positionStats, d => d.median)

  return { positionMin, positionMax }
}

// get min/max median salaries of all agencies in comptroller
function getMaxMinAgenciesMedian(agencyStats) {
  // variables for the color scale
  let agenciesMin = d3.min(agencyStats, d => d.median),
    agenciesMax = d3.max(agencyStats, d => d.median)

  return { agenciesMin, agenciesMax }
}

// get tick values for color scale
function getColorScaleTicks(agencyStats) {
  let { agenciesMin, agenciesMax } = getMaxMinAgenciesMedian(agencyStats)

  // ticks for color scale
  let start = agenciesMin,
    stop = agenciesMax + 50000, 
    count = 7,
    step = d3.tickStep(start, stop, count)

  let ticks = d3.range(Math.floor(start / step) * step, Math.ceil(stop / step) * step + step / 2, step)

  return ticks
}

// create d3 linear color scale
// d3.scaleSequential(d3.interpolatePuBuGn).domain(d3.extent(otherBubbles, d => d.median))
// https://observablehq.com/@d3/working-with-color
// for the color scale, set the upper limit as the max of the salaries
// old scale: colorScale = d3.scaleSequential(d3.interpolatePuBuGn).domain([this.salariesMin, this.salariesMax])
function createColorScale(agencyStats) {
  let ticks = getColorScaleTicks(agencyStats)

  // color scale
  let colorScale = d3.scaleLinear()
    .domain(ticks)
    .range(variables.beeswarmColorSwatches)
    .interpolate(d3.interpolateRgb.gamma(2.2)) // https://www.cambridgeincolour.com/tutorials/gamma-correction.htm
    .clamp(true)

  return colorScale
}

// custom force to fit bubbles within the constraint of the chart width/height
// source: https://tomroth.com.au/fdg-bounding-box/s
function boxForce(positionStats, sqrtScale, chartWidth, chartHeight) {
  for (var i = 0, n = positionStats.length; i < n; ++i) {
    let curr_node = positionStats[i]
    curr_node.x = Math.max(sqrtScale(curr_node.count), Math.min(chartWidth - sqrtScale(curr_node.count), curr_node.x))
    curr_node.y = Math.max(sqrtScale(curr_node.count), Math.min(chartHeight - sqrtScale(curr_node.count), curr_node.y))
  }
}

export { calculateSqrtScaleRange, getMaxMinPositionMedian, getMaxMinAgenciesMedian, createColorScale, boxForce }
